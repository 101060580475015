import { render, staticRenderFns } from "./CreateOrder.vue?vue&type=template&id=dd67760e&scoped=true"
import script from "./CreateOrder.vue?vue&type=script&lang=js"
export * from "./CreateOrder.vue?vue&type=script&lang=js"
import style0 from "./CreateOrder.vue?vue&type=style&index=0&id=dd67760e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd67760e",
  null
  
)

export default component.exports